/* * {
  color: #424242;
} */
.hero {
  width: 100vw;
  height: 60vh;
  background-image: url("../../assets/hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.looking {
  font-family: "Poppins", Arial;
  font-size: 60px;
  font-weight: bolder;
  line-height: 72px;
  letter-spacing: 0em;
  text-transform: uppercase;
  color: #424242;
}
.move {
  font-family: "Montserrat", Arial;
  color: #ff6300;
  border-bottom: 6px dotted #ff6300;
  font-weight: bolder;
  font-size: 62px;
  line-height: 72px;
}

.quality {
  text-transform: capitalize;
  font-family: "Montserrat", Arial;
  font-weight: bolder;
  font-size: 32px;
  line-height: 39px;
  color: #424242;
}

.hassle {
  text-transform: initial;
  font-family: "Montserrat", Arial;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #424242;
}
.input_ {
  color: #ff6300;
  border: 1px solid white;
  padding: 10px;
  width: 50vw;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 53px;
}

@media only screen and (max-width: 600px) {
  .looking {
    font-size: 30px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
  }
  .move {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }
  .quality {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
  .hassle {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}
